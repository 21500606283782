import styled from 'styled-components';

export const StyledDisplayNonediv = styled.div`
	@media screen and (min-width: 480px) {
		display: none;
	}
`;
export const StyledDisplayNonedivtwo = styled.div`
	@media screen and (max-width: 600px) {
		display: none;
	}
`;
export const StyledFlexxdiv = styled.div`
	display: flex;
`;

export const StyledFleRightdiv = styled.div`
	display: flex;
	justify-content: right;
	align-items: center;
	margin-left: 50px;
	font-family: sans-serif;
	@media screen and (max-width: 480px) {
		margin-left: 380%;
	}
`;
export const StyledmenuNamediv = styled.div`
	font-size: 16px;
	font-weight: 400;
	padding: 8px 20px 8px 10px;
	color: #3e3d3d;
	font-family: 'Poppins', sans-serif;
	&:hover {
		color: #ed3237;
		// background: #f4f4f4;
	}
`;
export const StyledSubMenuNamediv = styled.div`
	display: inline-block;
	min-width: 95%;
	padding: 8px 20px 8px 10px;
	transition: 0.2s ease-out;
	color: #777;
	font-size: 16px;
	font-weight: 400;
	font-family: 'Poppins', sans-serif;
`;
export const StyledIcondiv = styled.div`
	font-size: 25px;
	margin-top: 23px;
	margin-left: 10px;
	font-family: sans-serif;
	@media screen and (max-width: 480px) {
		font-size: 19px;
		margin-top: 13px;
	}
`;
export const StyledReddiv = styled.div`
	color: #ed3237;
`;
export const StyledRedbottomdiv = styled.div`
	color: #ed3237;
	margin-top: -10px;
`;
export const Styledtextdiv = styled.div`
	font-size: 24px;
	font-weight: 900;
	font-family: sans-serif;
`;
export const StyledegaMenudiv = styled.div`
	background: white;
`;
