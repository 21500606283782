import { createSlice } from '@reduxjs/toolkit';

export const dealerListSlice = createSlice({
	name: 'dealerList',
	initialState: {},
	reducers: {
		savedealerListArr: (state, action) => {
			state.dealerListArr = action.payload;
		},
	},
});

export const { savedealerListArr } = dealerListSlice.actions;

export default dealerListSlice.reducer;
