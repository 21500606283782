import { createSlice } from '@reduxjs/toolkit';

export const productsListSlice = createSlice({
	name: 'productsList',
	initialState: {},
	reducers: {
		saveProductsListArr: (state, action) => {
			state.productsListArr = action.payload;
		},
	},
});

export const { saveProductsListArr } = productsListSlice.actions;

export default productsListSlice.reducer;
