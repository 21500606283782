import { createSlice } from '@reduxjs/toolkit';

export const wishlistSlice = createSlice({
	name: 'wishlist',
	initialState: { wishlistData: [], isAdded: true },
	reducers: {
		removefromWishlist: (state, action) => {
			state.wishlistData = [...action.payload];
		},

		setWishlistData: (state, action) => {
			state.wishlistData = [...state.wishlistData, action.payload];
			// console.log('......wishlistdata ', action.payload);
		},
	},
});

export const { removefromWishlist, setWishlistData } = wishlistSlice.actions;

export default wishlistSlice.reducer;
