import { createSlice } from '@reduxjs/toolkit';

export const cartSlice = createSlice({
	name: 'cart',
	initialState: { cartData: [] },
	reducers: {
		updateCartData: (state, action) => {
			state.cartData = action.payload;
		},
	},
});

export const { updateCartData } = cartSlice.actions;

export default cartSlice.reducer;
