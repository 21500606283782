import { createSlice } from '@reduxjs/toolkit';

export const technicianListSlice = createSlice({
	name: 'technicianList',
	initialState: {},
	reducers: {
		saveTechnicianListArr: (state, action) => {
			state.productsListArr = action.payload;
		},
	},
});

export const { saveTechnicianListArr } = technicianListSlice.actions;

export default technicianListSlice.reducer;
