import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import styled from "styled-components";
// import SideMenu from '../../../component/SideMenu/SideMenu';
import { BiSearch } from "react-icons/bi";
import { FaHeart, FaChevronDown, FaChevronRight, FaBars } from "react-icons/fa";
import "./header.css";
import Logo from "asset/image/BATHWARE_FINAL_LOGO.png";
import callicon from "asset/image/call-icon.png";
// import { StyledButtonLink } from "theme/StyledComponents";
import "bootstrap/dist/css/bootstrap.css";
// import { DropdownItem } from 'reactstrap';
import { css } from "styled-components";
import { Button } from "react-bootstrap";
// import { toast } from "react-toastify";
import { endpoint } from "api/endpoint";
import { apiHandler } from "api";
import { MdClear } from "react-icons/md";
import { SiWhatsapp } from "react-icons/si";
import { StyledDisplayNonedivtwo, StyledDisplayNonediv } from "./Header.styles";
import {
  saveSelectedCategory,
  saveSelectedType,
} from "features/homepage/homeDataSlice";
import { saveSearchProducts } from "features/productsearchlist/productsearchListSlice";
// import MobileHeader from "./MobileHeader";
const MobileHeader = React.lazy(() => import("./MobileHeader"));
import useWindowSize from "hooks/useWindowSize";
import { TbHeartHandshake } from "react-icons/tb";
// const Nav = styled.div`
//   height: 80px;
//   display: flex;
//   justify-content: flex-start;
//   align-items: center;
//   color: black;
// `;

// const NavIcon = styled(Link)`
//   margin-left: 1rem;
//   margin-right: 1rem;
//   font-size: 3rem;
//   height: 80px;
//   color: black;
//   display: flex;
//   justify-content: flex-start;
//   align-items: center;
// `;

// const SidebarNav = styled.nav`
//   background: #15171c;
//   width: 250px;
//   height: 100vh;
//   display: flex;
//   justify-content: center;
//   position: fixed;
//   top: 0;
//   left: ${({ sidebar }) => (sidebar ? "0" : "-100%")};
//   transition: 350ms;
//   z-index: 10;
// `;

// const SidebarWrap = styled.div`
//   width: 100%;
// `;

// const StyledButtonLogin = styled(StyledButtonLink)`
//   margin-top: -20px !important;
//   color: #fff !important;
// `;

// const StyledCartIconBadge = styled.span`
//   height: 20px;
//   min-width: 20px;
//   padding: 0 3px;
//   text-align: center;
//   background: #c32626;
//   position: absolute;
//   z-index: 2;
//   top: -5px;
//   border-radius: 50%;
//   right: 37px;
//   font-weight: bold;
//   font-size: 14px;
//   color: #fff;

//   @media screen and (max-width: 760px) {
//     right: 0;
//   }
// `;

// const StyledCartLink = styled.a`
//   position: relative;
// `;
const Maindiv = styled.div`
  z-index: 100;
  display: flex;
  align-items: center;
  width: 100%;
  height: 50px;
  background: white;
  ${(props) =>
    props.fixed &&
    css`
      position: fixed;
      top: 0;
    `}
`;

const INTERNATION_CAT_ID = 159;

const Header = ({ refresh }) => {
  const { width } = useWindowSize();
  const [showMobileHeader, setShowMobileHeader] = useState(false);
  const [blurON, setBlurON] = useState();
  //   const [modal, setModal] = React.useState(false);
  //   const toggle = () => setModal(!modal);
  const [aboutuskey, setAboutUsKey] = useState();
  const [newRefresh, setNewRefresh] = useState();
  const [show, setShow] = useState(false);
  const [style, setStyle] = useState({ color: "white" });
  const [styleproducticon, setStyleProducIcon] = useState({ color: "white" });
  const [stylemediaicon, setStyleMediaIcon] = useState({ color: "white" });
  //   const [submenu, setSubMenu] = useState({ color: "white" });
  const [stylelocateusicon, setStyleLocateUsIcon] = useState({
    display: "none",
  });
  // for sub menu icon
  const [hidesuggetion, setHideSuggestion] = useState(false);
  const [product, setProduct] = useState({});
  const [showsuggestions, setShowSuggestions] = useState(false);

  const [filteredSuggestions, setFilteredSuggestions] = useState([]);

  //   const [showsubmenuicon, setShowSubMenuIcon] = useState({});

  const [showicon, setShowicon] = useState(false);
  const { pathname } = useLocation();
  //   const handleClose = () => setShow(false);
  const handleShow = () => setShow((prevState) => !prevState);
  const controls = useRef();
  const [fixedposition, setFixedPosition] = useState(false);
  const [productList, setProductList] = useState([]);
  //   const [sidebar, setSidebar] = useState(false);

  //   const { authData, userData } = useSelector((state) => state.login);
  const { categories } = useSelector((state) => state.home);
  const { filterProducts } = useSelector((state) => state.filterProductsSearch);

  // const [newvar, setNewVar] = useState('');
  //   const { isAuthenticated } = authData;
  //   const { firstname } = userData;
  // variables for search bar
  const [inputvalue, setInputValue] = useState("");
  // ends here
  const history = useHistory();
  const dispatch = useDispatch();
  const handleSearchClick = () => {
    if (inputvalue !== "") {
      history.push("/search-products/" + inputvalue);
    }
  };
  const handleAboutus = (aboutkey) => {
    if (aboutkey === "about") {
      history.push("/about-us");
    } else {
      const state = { aboutKey: aboutkey };
      history.push("/about-us", state);
    }
  };
  useEffect(() => {
    // console.log('clicked');
    setShow(false);
  }, [pathname]);
  // serched and autocomplete
  useEffect(() => {
    // console.log('LISTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTT', filterProducts);
  }, [filterProducts]);
  const createQueryParam = (inputvalue) => {
    return (
      // '?searchCriteria[filter_groups][0][filters][0][field]=name&searchCriteria[filter_groups][0][filters][0][condition_type]=like&searchCriteria[filter_groups][0][filters][0][value]=' +
      // inputvalue +
      // '%'
      "?searchCriteria[filter_groups][0][filters][0][field]=name&searchCriteria[filter_groups][0][filters][0][condition_type]=like&searchCriteria[filter_groups][0][filters][0][value]=%" +
      inputvalue +
      "%&searchCriteria[filter_groups][0][filters][1][field]=description&searchCriteria[filter_groups][0][filters][1][condition_type]=like&searchCriteria[filter_groups][0][filters][1][value]=%" +
      inputvalue +
      "%&searchCriteria[filter_groups][1][filters][0][field]=status&searchCriteria[filter_groups][1][filters][0][condition_type]=eq&searchCriteria[filter_groups][1][filters][0][value]=1"
    );
  };
  // const handleInternationalcat = () => {
  // 	dispatch(saveSelectedCategory('ji-ranges'));
  // };
  useEffect(() => {
    if (inputvalue === "") {
      setHideSuggestion(false);
      // setShowicon(!showicon);
    }
  }, [inputvalue]);
  const getSearchedProducts = async (inputvalue) => {
    if (inputvalue.length < 3) return;

    const queryParam = createQueryParam(inputvalue);
    const result = await apiHandler({
      url: endpoint.PRODUCT_SEARCH + queryParam,
    });
    setProduct(result.data);
    setShowSuggestions(true);

    // console.log('PRODUCTSEARCH - ', result.data);
    setFilteredSuggestions(result.data && result.data.items);
    if (result.data && result.data.items.length != 0) {
      setShowSuggestions(true);
      dispatch(saveSearchProducts(result.data));
      setHideSuggestion(true);
      // setModal(!modal);
    } else {
      setShowSuggestions(false);
      setHideSuggestion(false);

      dispatch(saveSearchProducts({}));
    }
    // console.log('inputchange', inputvalue);

    // console.log('FilteredSuggestion', filteredSuggestions);
    // setProduct(result.data);
    // Get filteredSuggestion from item
    // set  filteredSuggestion  in state
    // set showSuggestions
  };
  const onChangeHandler = (text) => {
    const textStr =
      !!text.length && text[0] === " " ? text.replace(" ", "") : text;
    const updatedtext = textStr.replace(/[^a-zA-Z0-9 ]/g, "");
    setInputValue(updatedtext);

    setShowSuggestions(false);
    if (updatedtext.length > 2) {
      getSearchedProducts(updatedtext);
    }
  };
  const onClickSubCat = async (urlKey) => {
    await history.push("/product-detail/" + urlKey);
    setShowSuggestions(false);
    setInputValue(inputvalue);
    dispatch(saveSearchProducts({}));
    setHideSuggestion(false);
  };
  const handleBlurOut = async (e) => {
    if (blurON === true) {
      setHideSuggestion(false);
      setShowicon(false);
      setInputValue("");
      getSearchedProducts("");
    }
  };
  const handleBlurOutMobileView = async (e) => {
    const myTimeout = setTimeout(function () {
      setHideSuggestion(false);
      setShowicon(false);
      setInputValue("");
      getSearchedProducts("");
    }, 100);
  };

  const onKeyDown = (e) => {
    if (e.keyCode === 13) {
      setShowSuggestions(false);
      handleSearchClick();
      setInputValue(inputvalue);

      // userInput: filteredSuggestions[activeSuggestion],
    }
    // } else if (e.keyCode === 38) {
    // 		if (activeSuggestion === 0) {
    // 			return;
    // 		}
    // 		this.setState({ activeSuggestion: activeSuggestion - 1 });
    // 	}
    // 	// User pressed the down arrow, increment the index
    // else if (e.keyCode === 40) {
    // if (activeSuggestion - 1 === filteredSuggestions.length) {
    // return;
  };
  // 		this.setState({ activeSuggestion: activeSuggestion + 1 });
  // }
  // };
  //Api's End here

  useEffect(() => {
    if (categories) {
      let list = [];
      categories.children_data &&
        categories.children_data.forEach((cat) => {
          if (cat.id === INTERNATION_CAT_ID) return;
          let childList = [];
          cat.children_data &&
            cat.children_data.forEach((subCat) => {
              childList.push({
                url_key: subCat.url_key,
                name: subCat.name,
                child: [],
              });
            });
          list.push({
            url_key: cat.url_key,
            name: cat.name,
            child: childList,
          });
        });
      setProductList(list);
    }
  }, [categories]);
  // const onSearch = (event) => {
  // 	props.history.push('/search-products/:filter');
  // };
  // we are using it as for temparory we will upgrade it
  useEffect(() => {
    handleClearAll();
  }, []);
  //Ends Here
  useEffect(() => {
    setNewRefresh(refresh);

    if (refresh !== newRefresh) {
      handleClearAll();
    }
  }, [refresh]);
  const arunjangra = () => {
    setBlurON(false);
  };
  const handleClearAll = () => {
    setInputValue("");
    setShowSuggestions(false);
  };
  const selectCategory = (catId) => {
    // set catId
    dispatch(saveSelectedCategory(catId));
    history.push("/products/" + catId);
  };
  const selectCategoryType = (catId, typeId) => {
    // set catId
    dispatch(saveSelectedCategory(catId));
    dispatch(saveSelectedType(typeId));
    history.push("/products/" + catId + "/" + typeId);
  };
  //   const handleLogout = (e) => {
  //     e.preventDefault();
  //     e.stopPropagation();
  //     dispatch({ type: "SIGNOUT" });
  //     toast.success("Logged Out Successfully!");
  //     history.push("");
  //     // window.setTimeout('alert("Logged Out Successfully!");window.close();', 500);
  //   };
  //   const showSidebar = () => setSidebar(!sidebar);

  useEffect(() => {
    if (width < 992) {
      setShowMobileHeader(true);
    }
  }, [width]);

  return (
    <header
      className="header"
      style={{ position: "fixed", zIndex: 101, width: "100vw" }}
    >
      <div
        className="header-middle sticky-header"
        data-sticky-options="{'mobile': true}"
      >
        <div className="container heightheader">
          <div className="header-left">
            <Button
              onClick={handleShow}
              className="mobile-menu-toggler"
              type="button"
            >
              {" "}
              <FaBars />
            </Button>
            {showMobileHeader && (
              <React.Suspense fallback={null}>
                <MobileHeader
                  productList={productList}
                  show={show}
                  handleShow={handleShow}
                  setAboutUsKey={setAboutUsKey}
                  handleAboutus={handleAboutus}
                  selectCategory={selectCategory}
                  selectCategoryType={selectCategoryType}
                />
              </React.Suspense>
            )}
            <a href="/" className="logo">
              <img
                src={Logo}
                alt=""
                width={"auto"}
                height={60}
                className="WebsiteLogo"
                fetchpriority="high"
              />
            </a>
          </div>

          {/* End .header-left */}
          <div className="header-center d-lg-block d-none">
            <div className="Top-dealer">
              <a href="/locate/dealer">
                <TbHeartHandshake /> Dealers
              </a>
            </div>
            <div className="header-contact d-none d-lg-block">
              <center>
                <h6>Customer Care </h6>
              </center>
              <div className="flex-tollfree">
                <span className="letter-space">
                  <SiWhatsapp className="whatup-icon spaceicon" />
                  <a href={"https://api.whatsapp.com/send?phone=8451057484"}>
                    {" "}
                    +91-8451057484
                  </a>
                </span>
                {/* <span className="letter-space">
                  <img className="spaceicon call-image" src={callicon} alt="" />
                  <a href="tel:+18002107484"> 1800 210 7484 </a>
                </span> */}
              </div>
              {/* <span>1800 210 7484</span> */}
            </div>
          </div>

          {/* End .headeer-center */}
          <div className="header-right">
            <div className="header-search header-search-popup header-search-category">
              <StyledDisplayNonedivtwo>
                <div className="header-search-wrapper positionofsearch">
                  <input
                    type="search"
                    className="form-control inerpadding"
                    name="q"
                    id="q"
                    placeholder="I'm searching for..."
                    value={inputvalue}
                    required=""
                    content="width=device-width, initial-scale=2, maximum-scale=2"
                    onChange={(e) => onChangeHandler(e.target.value)}
                    onKeyDown={(e) => onKeyDown(e)}
                    onBlur={(e) => handleBlurOut(e)}

                    // onBlur={() => {
                    // 	setTimeout(() => {
                    // 		setSuggestions([]);
                    // 	}, 100);
                    // }}
                  />
                  {inputvalue.length > 0 && (
                    <span
                      style={{
                        position: "absolute",
                        top: 8,
                        right: 33,
                        cursor: "pointer",
                        background: " white",
                        paddingRight: "5px",
                      }}
                    >
                      <MdClear
                        className="Ross-icon"
                        onClick={(e) => handleClearAll(e)}
                      />
                    </span>
                  )}
                  <a
                    className="search-toggle"
                    role="button"
                    style={{ position: "absolute", top: 0, right: 3 }}
                    onClick={() => handleSearchClick()}
                  >
                    <BiSearch className="margin5px" />
                  </a>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    width: 90,
                  }}
                >
                  {/* <Modal
										className='modal-content-arun'
										isOpen={modal}
										toggle={() => toggle()}
									>
										<ModalBody
											className='modal-body-arun'
											style={{ background: '#f5f5f5' }}
										>
											<ul
												style={{ maxHeight: '250px', overflowY: 'scroll' }}
												className='suggestion-font'
											>
												{filteredSuggestions &&
													filteredSuggestions.map((filter, index) => (
														<>
															{filter &&
																filter.custom_attributes.map((item, j) => (
																	<>
																		{item.attribute_code === 'url_key' && (
																			<li
																				className='list-suggestion'
																				onClick={() =>
																					onClickSubCat(item.value)
																				}
																				key={index}
																			>
																				{filter.name}
																			</li>
																		)}
																	</>
																))}
														</>
													))}
											</ul>
										</ModalBody>
									</Modal>*/}
                </div>
                {hidesuggetion === true && (
                  <>
                    {filterProducts &&
                      filterProducts.items &&
                      filterProducts.items.length !== 0 && (
                        <div
                          style={{
                            // 	position: 'absolute',
                            // 	background: '#EEE9E6',
                            // 	left: '0',
                            // 	right: '0',
                            // 	zIndex: '1',
                            // 	borderRadius: '10px',
                            // 	border: '5px solid #ccc',
                            overflowY: "scroll",
                            height: "250px",
                          }}
                          className="searchdropdown-list"
                        >
                          <ul
                            onMouseEnter={(e) => {
                              setBlurON(false);
                            }}
                            onMouseLeave={(e) => {
                              setBlurON(true);
                            }}
                            // style={{
                            // 	height: '250px',
                            // 	overflowY: 'scroll',
                            // }}
                            className="suggestion-font"
                          >
                            {filterProducts &&
                              filterProducts.items &&
                              filterProducts.items.map((filter, index) => (
                                <>
                                  {filter &&
                                    filter.custom_attributes &&
                                    filter.custom_attributes.map((raw, j) => (
                                      <>
                                        {raw.attribute_code === "url_key" && (
                                          <li
                                            className="list-suggestion"
                                            onClick={() => {
                                              onClickSubCat(raw.value);
                                              // setHideSuggestion(false);
                                            }}
                                            key={j}
                                          >
                                            {filter.name}
                                          </li>
                                        )}
                                      </>
                                    ))}
                                </>
                              ))}
                          </ul>
                        </div>
                      )}
                  </>
                )}
              </StyledDisplayNonedivtwo>
              <StyledDisplayNonediv>
                {" "}
                <button
                  className="seaxch"
                  onClick={() => setShowicon(!showicon)}
                >
                  <BiSearch />
                </button>
                {/* <form action='' method='get'> */}
                {showicon ? (
                  <>
                    <div
                      className="header-search-wrapper positionofsearch"
                      style={{
                        position: "absolute",
                        top: "32px",
                        right: 0,
                        width: "20rem",
                      }}
                    >
                      <input
                        type="text"
                        className="form-control"
                        name="q"
                        id="q"
                        value={inputvalue}
                        placeholder="I'm searching for..."
                        required=""
                        onChange={(e) => onChangeHandler(e.target.value)}
                        onKeyDown={(e) => onKeyDown(e)}
                        onBlur={(e) => handleBlurOutMobileView(e)}
                      />
                      <a
                        className="search-toggle"
                        role="button"
                        style={{
                          position: "absolute",
                          top: -1,
                          right: -6,
                          // background: 'white',
                          borderRadius: "25px",
                        }}
                        onClick={() => handleSearchClick()}
                      >
                        <BiSearch className="margin5px" />
                      </a>
                      {inputvalue.length > 0 && (
                        <span
                          style={{
                            position: "absolute",
                            top: 9,
                            right: 22,
                            cursor: "pointer",
                            background: "white",
                            paddingRight: "5px",
                          }}
                          onClick={() => handleClearAll()}
                        >
                          <MdClear />
                        </span>
                      )}
                      {hidesuggetion === true && (
                        <>
                          {showsuggestions && (
                            <div
                              style={{
                                // 	position: 'absolute',
                                // 	background: '#EEE9E6',
                                // 	left: '0',
                                marginTop: "40px",
                                overflowY: "scroll",
                                height: "250px",
                                // 	right: '0',
                                // 	zIndex: '1',
                                // 	borderRadius: '10px',
                                // 	border: '5px solid #ccc',
                              }}
                              className="searchdropdown-list"
                            >
                              <ul
                                // onClick={(e) => arunjangra(e)}
                                // onMouseEnter={(e) => {
                                // 	setBlurON(false);
                                // }}
                                // onMouseLeave={(e) => {
                                // 	setBlurON(true);
                                // }}
                                style={{
                                  overflowY: "scroll",
                                  height: "250px",
                                }}
                                className="suggestion-font"
                              >
                                {filterProducts &&
                                  filterProducts.items &&
                                  filterProducts.items.map((filter, index) => (
                                    <>
                                      {filter &&
                                        filter.custom_attributes &&
                                        filter.custom_attributes.map(
                                          (raw, j) => (
                                            <>
                                              {raw.attribute_code ===
                                                "url_key" && (
                                                <li
                                                  className="list-suggestion"
                                                  onClick={() => {
                                                    onClickSubCat(raw.value);
                                                    arunjangra();
                                                    // setHideSuggestion(false);
                                                  }}
                                                  key={j}
                                                >
                                                  {filter.name}
                                                </li>
                                              )}
                                            </>
                                          )
                                        )}
                                    </>
                                  ))}
                                {/* {filteredSuggestions &&
																filteredSuggestions.map((filter, index) => (
																	<>
																		{filter &&
																			filter.custom_attributes.map(
																				(item, j) => (
																					<>
																						{item.attribute_code ===
																							'url_key' && (
																							<li
																								className='list-suggestion'
																								onClick={() =>
																									onClickSubCat(item.value)
																								}
																								key={index}
																							>
																								{filter.name}
																							</li>
																						)}
																					</>
																				)
																			)}
																	</>
																))} */}
                              </ul>
                            </div>
                          )}
                        </>
                      )}
                    </div>
                  </>
                ) : null}
                {/* </form> */}
              </StyledDisplayNonediv>
            </div>{" "}
            <a href="/wishlist" className="header-icon">
              {/* <i className='fa fa-heart'></i> */}
              <FaHeart className="margin5px" />
            </a>
            {/* <div className='header-dropdown'>
							{' '}
							<a href='#' className='pl-0'>
								{/* <i className='fa fa-user'></i> 
								<BsFillPersonFill style={{ fontSize: '25px' }} />
								<FaChevronDown />
							</a>
							<div className='header-menu'>
								{isAuthenticated ? (
									<ul>
										{firstname && firstname !== '' && (
											<li className='pointercss'>
												<a>{'Hi ' + firstname}</a>
											</li>
										)}
										<li className='pointercss' onClick={(e) => handleLogout(e)}>
											<a>Logout</a>
										</li>
									</ul>
								) : (
									<ul>
										<li className='pointercss'>
											<a href='/login'>Log-in</a>
										</li>
										<li className='pointercss'>
											<a href='/sign-up'>Register</a>
										</li>
									</ul>
								)}
							</div>
						</div> */}
          </div>
          {/* End .header-right */}
        </div>
        {/* End .container */}
      </div>
      {/* End .header-middle */}
      <Maindiv
        className="header-bottom sticky-header d-none d-lg-flex"
        data-sticky-options="{'mobile': false}"
        fixed={fixedposition}
        ref={controls}
      >
        <div className="container">
          <nav className="main-nav w-100">
            <ul className="menu w-100">
              <li>
                <a
                  onMouseEnter={(e) => {
                    setStyle({ color: "#ed3231" });
                  }}
                  onMouseLeave={(e) => {
                    setStyle({ color: "black" });
                  }}
                >
                  <div className="menu-name about-us-menu">
                    <span
                      onClick={(e) => {
                        setAboutUsKey(e);
                        handleAboutus("about");
                      }}
                    >
                      About Us
                    </span>
                    <FaChevronDown style={style} className="ml-1" />
                  </div>
                </a>
                <ul
                  onMouseEnter={(e) => {
                    setStyle({ display: "block" });
                  }}
                  onMouseLeave={(e) => {
                    setStyle({ color: "white" });
                  }}
                >
                  <li>
                    <a
                      className="about-us-menu"
                      onClick={(e) => {
                        setAboutUsKey(e);
                        handleAboutus("about");
                      }}
                    >
                      H & R Johnson
                    </a>
                  </li>

                  <li>
                    <a
                      className="about-us-menu"
                      onClick={(e) => {
                        setAboutUsKey(e);
                        handleAboutus("bath_division");
                      }}
                    >
                      Johnson Bath Division
                    </a>
                  </li>
                  <li>
                    <a
                      className="about-us-menu"
                      onClick={(e) => {
                        setAboutUsKey(e);
                        handleAboutus("innovation");
                      }}
                    >
                      Innovation & Quality
                    </a>
                  </li>
                  <li>
                    <a
                      className="about-us-menu"
                      // onClick={(e) => {
                      // 	setAboutUsKey(e);
                      // 	handleAboutus('awards');
                      // }}
                      href="/awards"
                    >
                      Awards & Accolades
                    </a>
                  </li>
                  <li>
                    <a className="about-us-menu" href="/testimonials">
                      Testimonial
                    </a>
                  </li>
                  <li>
                    <a className="about-us-menu" href="/faqs">
                      FAQs
                    </a>
                  </li>
                </ul>
              </li>

              <li>
                <a
                  href="#"
                  onMouseEnter={(e) => {
                    setStyleProducIcon({ display: "block" });
                  }}
                  onMouseLeave={(e) => {
                    setStyleProducIcon({ color: "white" });
                  }}
                >
                  <div className="menu-name">
                    <span>Products</span>
                    <FaChevronDown style={styleproducticon} className="ml-1" />
                  </div>
                </a>
                <ul
                  className="dropdown-menu-menu"
                  onMouseEnter={(e) => {
                    setStyleProducIcon({ display: "block" });
                  }}
                  onMouseLeave={(e) => {
                    setStyleProducIcon({ color: "white" });
                  }}
                >
                  {productList &&
                    productList.map((prod) => (
                      <>
                        {prod.child.length > 0 ? (
                          <>
                            {prod.name === "Johnson International" ||
                            prod.name === "Germ Free" ? null : (
                              <>
                                <li className="pointer" key={prod.url_key}>
                                  <a
                                    onClick={() => selectCategory(prod.url_key)}
                                  >
                                    <div className="menu-name">
                                      {prod.name}
                                      {
                                        <>
                                          <FaChevronRight className="ml-1" />
                                        </>
                                      }
                                    </div>
                                  </a>
                                  <ul>
                                    {prod.child.map((sc, i) => (
                                      <li className="pointer" key={sc.url_key}>
                                        <a
                                          onClick={() =>
                                            selectCategoryType(
                                              prod.url_key,
                                              sc.url_key
                                            )
                                          }
                                        >
                                          {sc.name}
                                        </a>
                                      </li>
                                    ))}
                                  </ul>
                                </li>
                              </>
                            )}
                          </>
                        ) : (
                          <li className="pointer" key={prod.url_key}>
                            <a onClick={() => selectCategory(prod.url_key)}>
                              {prod.name}
                            </a>
                          </li>
                        )}
                      </>
                    ))}
                </ul>
              </li>
              <li>
                <a href="/international">Johnson International</a>
              </li>

              {/* <li>
								<a
									href='#'
									onMouseEnter={(e) => {
										setStyleProducIcon({ display: 'block' });
									}}
									onMouseLeave={(e) => {
										setStyleProducIcon({ color: 'white' });
									}}
								>
									<div className='menu-name'>
										{productList &&
											productList.map((prod) => (
												<>
													{prod.name === 'Johnson International' && (
														<li
															className='pointer'
															onClick={() => selectCategory(prod.url_key)}
															key={prod.url_key}
														>
															<span>{prod.name}</span>
															<FaChevronDown
																style={styleproducticon}
																className='ml-1'
															/>
														</li>
													)}
												</>
											))}
									</div>
								</a>
								<ul
									className='dropdown-menu-menu'
									onMouseEnter={(e) => {
										setStyleProducIcon({ display: 'block' });
									}}
									onMouseLeave={(e) => {
										setStyleProducIcon({ color: 'white' });
									}}
								>
									{productList &&
										productList.map((prod) => (
											<>
												{prod.name === 'Johnson International' && (
													<li className='pointer' key={prod.url_key}>
														{prod.child.length > 0 ? (
															<>
																{prod.child.map((sc, i) => (
																	<li className='pointer' key={sc.url_key}>
																		<a
																			onClick={() =>
																				selectCategoryType(
																					prod.url_key,
																					sc.url_key
																				)
																			}
																		>
																			{sc.name}
																		</a>
																	</li>
																))}
															</>
														) : (
															<a onClick={() => selectCategory(prod.url_key)}>
																{prod.name}
															</a>
														)}
													</li>
												)}
											</>
										))}
								</ul>
							</li> */}
              <li>
                <a href="/catalogue">Catalogue</a>
              </li>
              <li>
                <a href="/solutions">Solutions</a>
              </li>
              <li>
                <a
                  href=""
                  onMouseEnter={(e) => {
                    setStyleMediaIcon({ display: "block" });
                  }}
                  onMouseLeave={(e) => {
                    setStyleMediaIcon({ color: "white" });
                  }}
                >
                  <div className="menu-name">
                    <span>Media</span>
                    <FaChevronDown style={stylemediaicon} className="ml-1" />
                  </div>
                </a>
                <ul
                  onMouseEnter={(e) => {
                    setStyleMediaIcon({ display: "block" });
                  }}
                  onMouseLeave={(e) => {
                    setStyleMediaIcon({ color: "white" });
                  }}
                >
                  {/* <li>
										<a href='/media/campaigns'>Campaigns</a>
									</li> */}
                  <li>
                    <a href="/ads_articles">Ads & Articles</a>
                  </li>

                  <li>
                    <a href="/events">Events</a>
                  </li>
                  <li>
                    <a href="/press-release">Press Release</a>
                  </li>

                  <li>
                    <a href="/media/newsletter">Newsletter</a>
                  </li>
                  <li>
                    <a href="/product-video">Videos</a>
                  </li>
                  <li>
                    <a href="/blogs">Blogs</a>
                  </li>
                </ul>
              </li>
              <li>
                <a href="/experience-center">Experience Centre</a>
              </li>
              <li>
                <a href="/bathroom-concepts">Bathroom Concepts</a>
              </li>
              <li>
                <a
                  // onMouseEnter={(e) => {
                  //   setStyleLocateUsIcon({ display: "block" });
                  // }}
                  // onMouseLeave={(e) => {
                  //   setStyleLocateUsIcon({ color: "white" });
                  // }}
                  href="/locate/contact-us"
                >
                  <div className="menu-name">
                    <span>Contact us</span>

                    {/* <FaChevronDown style={stylelocateusicon} className="ml-1" /> */}
                  </div>
                </a>
                {/* <ul
                  onMouseEnter={(e) => {
                    setStyleLocateUsIcon({ display: "block" });
                  }}
                  onMouseLeave={(e) => {
                    setStyleLocateUsIcon({ color: "white" });
                  }}
                > */}
                {/* <li>
                    <a href="/locate/dealer">Dealers</a>
                  </li> */}
                {/* <li>
										<a href='/locate/technician'>Technician/Plumber</a>
									</li> */}
                {/* <li>
										<a href='/locate/registered-office'>Registered Office</a>
									</li> */}
                {/* <li>
                    <a href="/locate/contact-us">Contact us</a>
                  </li>
                </ul> */}
              </li>
            </ul>
          </nav>
        </div>
        {/* End .container */}
      </Maindiv>
      {/* End .header-bottom */}
    </header>
  );
};
const Placeholder = styled.div`
  height: 60px;
`;

export default Header;
