import { lazy } from "react";
import { Route } from "react-router-dom";

import HomePage from "container/HomePage/HomePage";
import { Redirect } from "react-router-dom/cjs/react-router-dom.min";
const WebsiteCampaign = lazy(() =>
  import("container/WebsiteCampaign/WebsiteCampaign")
);
const WebsiteCampaignThanksYou = lazy(() =>
  import("container/WebsiteCampaign/WebsiteCampaignThanksYou")
);
const Awards = lazy(() => import("container/Awards/Awards"));
const BathRoomConcept = lazy(() =>
  import("container/BathroomConcepts/BathroomConcepts")
);
const BathroomCost = lazy(() => import("container/BathroomCost/BathroomCost"));
const Campaigns = lazy(() => import("container/Campaigns/Campaigns"));
const Catalogue = lazy(() => import("container/Catalogue/Catalogue"));
const CorporateOffice = lazy(() =>
  import("container/CorporateOffice/CorporateOffice")
);
const DealerLocator = lazy(() =>
  import("container/DealerLocator/DealerLocator")
);
const Disclaimer = lazy(() => import("container/Disclaimer/disclaimer"));
const Enquiry = lazy(() => import("container/Enquiry/enquiry"));
const ExperienceCenter = lazy(() =>
  import("container/ExperienceCenter/ExperienceCenter")
);
const ExploreBathroomConcept = lazy(() =>
  import("container/ExploreBathroomConcept/ExploreConcept")
);
const ExploreBlogs = lazy(() => import("container/ExploreBlogs/Exploreblogs"));
const FAQs = lazy(() => import("container/FAQs/Faqs"));
const EventsPage = lazy(() => import("container/EventsPage/Eventspage"));
const International = lazy(() =>
  import("container/International/International")
);
const Login = lazy(() => import("container/Login/Login"));
const NewsLetter = lazy(() => import("container/NewsLetter/NewsLetter"));
const PressRelease = lazy(() => import("container/PressRelease/PressRelease"));
const PrivacyPolicy = lazy(() =>
  import("container/Privacypolicy/Privacypolicy")
);
const RedirectPage = lazy(() =>
  import("container/RedirectComponent/RedirectPage")
);
const Registeredoffice = lazy(() =>
  import("container/RegisteredOffice/RegisteredOffice")
);
const SignUp = lazy(() => import("container/Signup/Signup"));
const Solutions = lazy(() => import("container/Solutions/Solutions"));
const Technician = lazy(() => import("container/Technician/Technician"));
const Termofuse = lazy(() => import("container/Termofuse/Termofuse"));
const Testimonial = lazy(() => import("container/Testimonials/Testimonial"));
const ThankYou = lazy(() => import("container/ThankYou/ThankYou"));
const ThankYouWishlist = lazy(() =>
  import("container/ThankYouWishlist/ThankYouWishlist")
);
const Videos = lazy(() => import("container/Videos/Videos"));
const WishlistPage = lazy(() => import("container/WishlistPage/WishlistPage"));
const Blogcategories = lazy(() =>
  import("container/BlogCategories/blogcategories")
);
const ExploreAwards = lazy(() =>
  import("container/ExploreAwards/exploreawards")
);
const AboutUs = lazy(() => import("container/AboutUs/AboutUs"));
const ProductListing = lazy(() =>
  import("container/ProductListing/ProductListing")
);
const ProductCategories = lazy(() =>
  import("container/ProductCategories/ProductCategories")
);
const SearchProduct = lazy(() =>
  import("container/SearchProducts/SearchProduct")
);
const ProductDetail = lazy(() =>
  import("container/ProductDetail/ProductDetail")
);

import REDIRECTIONS from "./asset/json/urlRedirections.json";

const ROUTES_MAP = {
  "/": <HomePage />,
  "/campaign": <WebsiteCampaign />,
  "/campaign/thank-you": <WebsiteCampaignThanksYou />,
  "/sign-up": <SignUp />,
  "/termofuse": <Termofuse />,
  "/disclaimer": <Disclaimer />,
  "/privacy/policy": <PrivacyPolicy />,
  "/bathroom-cost": <BathroomCost />,
  "/catalogue": <Catalogue />,
  "/ads_articles": <Campaigns />,
  "/press-release": <PressRelease />,
  "/media/newsletter": <NewsLetter />,
  "/events": <EventsPage />,
  "/locate/dealer": <DealerLocator />,
  "/product-video": <Videos />,
  "/enquiry": <Enquiry />,
  "/faqs": <FAQs />,
  "/locate/registered-office": <Registeredoffice />,
  "/locate/contact-us": <CorporateOffice />,
  "/thank-you": <ThankYou />,
  "/thankyou": <ThankYouWishlist />,
  "/testimonials": <Testimonial />,
  "/locate/technician": <Technician />,
  "/experience-center": <ExperienceCenter />,
  "/solutions": <Solutions />,
  "/international": <International />,
  "/login": <Login />,
  "/wishlist": <WishlistPage />,
  "/awards": <Awards />,
  "/products-inner.php": <RedirectPage />,
};

function CustomRoute({ children, ...rest }) {
  return (
    <Route
      {...rest}
      render={({ location }) => {
        const { search, pathname } = location;

        const redirection = REDIRECTIONS.find(
          (item) => item.old_Urlkey === pathname && item.new_UrlKey != pathname
        );

        if (pathname === "/search" && search && search.includes("cache")) {
          const johnsonOrigin = "johnsonbathrooms.in";
          const cachePath = search.split(johnsonOrigin)[1];
          const actualPathStr = cachePath && cachePath.split("&")[0];
          const actualPath =
            actualPathStr && actualPathStr.replaceAll("%2F", "/");

          console.log("cachePath", cachePath);
          console.log("actualPathStr", actualPathStr);
          console.log("actualPath", actualPath);

          if (actualPath === "") return <HomePage />;
          else if (Object.hasOwn(ROUTES_MAP, actualPath))
            return ROUTES_MAP[actualPath];
          else if (actualPath.includes("/bathroom-concepts")) {
            const splitPaths = actualPath.split("/");
            const secondPath = splitPaths[2];
            if (!!secondPath) return <ExploreBathroomConcept id={secondPath} />;
            else return <BathRoomConcept />;
          } else if (actualPath.includes("/blogs")) {
            const splitPaths = actualPath.split("/");
            const secondPath = splitPaths[2];
            if (!!secondPath) return <ExploreBlogs id={secondPath} />;
            else return <Blogcategories />;
          } else if (actualPath.includes("/award")) {
            const splitPaths = actualPath.split("/");
            const secondPath = splitPaths[2];
            return <ExploreAwards id={secondPath} />;
          } else if (actualPath.includes("/about-us")) {
            const splitPaths = actualPath.split("/");
            const secondPath = splitPaths[2];
            if (!!secondPath) return <AboutUs aboutKey={secondPath} />;
            else return <AboutUs />;
          } else if (actualPath.includes("/products")) {
            const splitPaths = actualPath.split("/");
            const secondPath = splitPaths[2];
            const thirdPath = splitPaths[3];
            if (!!thirdPath)
              return <ProductListing category={secondPath} type={thirdPath} />;
            else return <ProductCategories category={secondPath} />;
          } else if (actualPath.includes("/search-products")) {
            const splitPaths = actualPath.split("/");
            const secondPath = splitPaths[2];
            return <SearchProduct filter={secondPath} />;
          } else if (actualPath.includes("/product-detail")) {
            const splitPaths = actualPath.split("/");
            const secondPath = splitPaths[2];
            return <ProductDetail urlKey={secondPath} />;
          } else return children;
        } else if (pathname === "/products/kitchen-sinks/sinks") {
          const redirectionURL = `/products/kitchen-sinks`;
          return <Redirect to={redirectionURL} />;
        } else if (
          pathname === "/products/showers-allied/heath-faucets" ||
          pathname === "/products/showers-allied/heath-fauctes"
        ) {
          const redirectionURL = `/products/showers-allied/health-faucets`;
          return <Redirect to={redirectionURL} />;
        } 
        // else if (redirection) {
        //   return <Redirect to={redirection.new_UrlKey} />;
        // } 
        else return children;
      }}
    />
  );
}

export default CustomRoute;
