import styled from 'styled-components';

export const StyledBackgroundColor = styled.div`
	background: #77777;
	// padding-bottom: 30px;
`;
export const StyledHeadingdiv = styled.div`
	font-size: 25px;
	line-height: 1.5;
	font-weight: 400;
	padding-top: 40px;
`;
export const StyledBordediv = styled.div`
	border-bottom: 3px solid #ed3237;
	height: 5px;
	width: 50px;
`;
export const StyledHeadingContent = styled.div`
	font-size: 16px;
	color: #636363;
	line-height: 24px;
	margin-top: 30px;
	margin-bottom: 1.5rem;
	font-family: 'Poppins', sans-serif;
	display: block;
	margin-block-start: 1em;
	margin-block-end: 1em;
	margin-inline-start: 0px;
	margin-inline-end: 0px;
	text-align: center;
	padding-left: 25%;
	padding-right: 25%;
	@media screen and (max-width: 480px) {
		padding-left: 10%;
		padding-right: 10%;
	}
`;
// ends here
