import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "./Style.css";

const ProductType = ({ imageUrl, productType, catgorycontent }) => {
  return (
    <figure className="effect-moses">
      <LazyLoadImage src={imageUrl} alt="img24" width={398} height={459} />
      <figcaption>
        <h2>{productType}</h2>
        <p className="textproductype">
          {catgorycontent}
          <span
            style={{ marginTop: 0, fontStyle: "normal" }}
            className="explore-btn"
          >
            Explore
          </span>
        </p>
      </figcaption>
    </figure>
  );
};
export default ProductType;
