import React, { useEffect, useState } from "react";
import { Carousel } from "react-bootstrap";
import "./banner.css";
import StaticBanner from "../../asset/image/static_banner.jpg";
import StaticBannerWebp from "../../asset/image/static_banner.webp";
import useWindowSize from "hooks/useWindowSize";

const Banner = ({ banner, type }) => {
  const { width } = useWindowSize();
  const [height, setHeight] = useState(null);

  useEffect(() => {
    setHeight((width * 31.25) / 100);
  }, [width]);

  return (
    <div
      className="banner-homepage-sk"
      style={{
        width: "100%",
        height: !!height ? `${height}px` : '',
      }}
    >
      <Carousel fade indicators={false}>
        {banner && !!banner.length ? (
          banner
            .filter((b) => b.type === type)
            .map((item, i) => (
              <Carousel.Item key={i}>
                <a href={item.hyperlink}>
                  <img
                    src={item.image_url}
                    alt={item.alt_text || 'banner'}
                    className="bannerimg1"
                    loading="lazy"
                    width={1920}
                    height={600}
                  />
                </a>
              </Carousel.Item>
            ))
        ) : (
          <Carousel.Item>
            <picture>
              <source type="image/webp" srcSet={StaticBannerWebp} />
              <source type="image/jpeg" srcSet={StaticBanner} />
              <img src={StaticBanner} alt="" fetchpriority="high" />
            </picture>
          </Carousel.Item>
        )}
      </Carousel>
    </div>
  );
};

export default Banner;
