import { createSlice } from '@reduxjs/toolkit';

export const searchDataSlice = createSlice({
	name: 'searchProducts',
	initialState: {
		filterProducts: [],
	},
	reducers: {
		saveSearchProducts: (state, action) => {
			state.filterProducts = action.payload;
			// console.log('SEARCH-PRODUCTS-FILTER-', state.filterProducts);
		},
		// saveProductListing: (state, action) => {
		// 	state.productFilter = action.payload;
		// },
	},
});

export const { saveSearchProducts } = searchDataSlice.actions;
export default searchDataSlice.reducer;
