import { createSlice } from '@reduxjs/toolkit';

const CATALOGUE = [
	{
		'Johnson Bathrooms': [
			{
				id: '1',
				title: 'Price List ',
				description: null,
				category: 'Johnson Bathrooms',
				image_url: 'http://20.204.0.166/media//catalogue/WnN_zone.jpg',
				pdf_url: false,
			},
		],
	},
	{
		'Johnson International': [
			{
				id: '2',
				title: 'Test Record',
				description: null,
				category: 'Johnson International',
				image_url: 'http://20.204.0.166/media//catalogue/MP885_4.jpg',
				pdf_url:
					'http://20.204.0.166/media//catalogue/Get_Started_With_Smallpdf.pdf',
			},
		],
	},
];

export const catalogueDataSlice = createSlice({
	name: 'catalogue',
	initialState: {
		catalogue: [],
		campaign: [],
		newsletter: [],
		pressrelease: [],
		event: [],
	},
	reducers: {
		// Redux Toolkit allows us to write "mutating" logic in reducers. It
		// doesn't actually mutate the state because it uses the immer library,
		// which detects changes to a "draft state" and produces a brand new
		// immutable state based off those changes
		saveCatalogue: (state, action) => {
			state.catalogue = action.payload;
		},

		saveCampaign: (state, action) => {
			state.campaign = action.payload;
		},

		saveNewsletter: (state, action) => {
			state.newsletter = action.payload;
		},

		savePressRelease: (state, action) => {
			state.pressrelease = action.payload;
		},
		saveEvent: (state, action) => {
			state.event = action.payload;
		},
	},
});

export const {
	saveCatalogue,
	saveCampaign,
	saveNewsletter,
	savePressRelease,
	saveEvent,
} = catalogueDataSlice.actions;

export default catalogueDataSlice.reducer;
