import React from "react";
import { StyleheadingButton } from "./Product.styles";
import { BsEye } from "react-icons/bs";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { FaHeart } from "react-icons/fa";
import "./Product.css";
// import { apiHandler } from 'api';
// import { endpoint } from 'api/endpoint';
import { toast } from "react-toastify";
import germfree from "asset/image/germ-free.png";
import international from "asset/image/Johnson Internantional Double.png";
import newIcon from "asset/image/new_icon.png";
import { setWishlistData } from "features/wishlist/wishlistSlice";

const Product = ({
  imageUrl,
  productname,
  code,
  available_color,
  urlKey,
  isNew,
  productId,
  productdiscription,
  productprice,
  productimages,
  logoflagcomponent,
  logoInternationalcompo,
  imageALT,
}) => {
  const dispatch = useDispatch();

  // const [isShown, setIsShown] = useState(false);
  // const { userData, authData } = useSelector((state) => state.login);
  // const history = useHistory();

  const addProductToWishlist = async (id) => {
    // if (!authData.authToken) {
    // 	toast.info('Please Log in or Register to add product to wishlist.');
    // 	history.push({
    // 		pathname: '/login',
    // 		state: { fromScreen: 'product_specification' },
    // 	});
    // 	return;
    // }

    // const result = await apiHandler({
    // 	url: `${endpoint.WISHLIST}/add/${id}?customer_id=${userData.id}`,
    // 	method: 'POST',
    // });

    // if (result.data) {
    // console.log('.......ProductImgees', productimages);
    const counter = "1";
    toast.success("Product added Successfully to the wishlist.");
    dispatch(
      setWishlistData({
        productname,
        code,
        productdiscription,
        // 		StyleFeatures,
        productprice,
        // 		productwarranty,
        // 		available_color,
        productId,
        // 		producttechnicaldrawing,
        productimages,
        counter,
      })
    );
  };
  // };
  return (
    <div className="product-default inner-quickview inner-icon">
      <figure style={{ minHeight: 269 }} className="imgproduct">
        <a href={"/product-detail/" + urlKey}>
          <img
            src={imageUrl}
            alt={imageALT}
            width={240}
            height={300}
            loading="lazy"
          />
        </a>

        {isNew ? (
          <span className="product-listing-newlabel">
            <img
              src={newIcon}
              className="newIconImageListing"
              alt="isNew"
              width={240}
              height={300}
              loading="lazy"
            />
          </span>
        ) : null}

        <span className="product-detail-germfree">
          {logoflagcomponent && (
            <span>
              <img
                src={germfree}
                alt="germ-free"
                width={240}
                height={300}
                loading="lazy"
              />
            </span>
          )}
          {logoInternationalcompo && (
            <span>
              <img
                src={international}
                alt="germ-free"
                width={240}
                height={300}
                loading="lazy"
              />
            </span>
          )}
        </span>

        <div
          className="btn-icon-group"
          onClick={() => addProductToWishlist(productId)}
        >
          {" "}
          <a
            href="javascript:void(0)"
            title="Wishlist"
            className="btn-icon btn-icon-wish product-type-simple"
          >
            <FaHeart className="icon-heart" />
          </a>
        </div>
      </figure>
      <a className="txtdec" href={"/product-detail/" + urlKey}>
        <div className="product-details">
          <h3
            className="product-title"
            style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            <span>{productname}</span>
          </h3>
          <div className="ratings-container">
            {available_color && (
              <ul className="availablecolor flexcolorproduct">
                <li>Available Color</li>
                {available_color.split(",").map((color, i) => (
                  <div key={i} className="paddingclr">
                    <div className="colorborderspec">
                      <StyleheadingButton color={color} />
                    </div>
                  </div>
                ))}
              </ul>
            )}
          </div>
          {/* {logoInternationalcompo && (
						<span className='product-detail-germfree'>
							<img src={international} alt='germ-free' />
						</span>
					)} */}
          {/* {logoflagcomponent && (
						<span className='product-detail-germfree'>
							<img src={germfree} alt='germ-free' />
						</span>
					)} */}
          <span>
            <div className="button-box">
              <BsEye className="fa fa-eye" /> View Details
            </div>
          </span>
        </div>
      </a>
    </div>
  );
};
export default Product;
