import React, { useState, useEffect, useMemo } from "react";
import { Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Banner from "component/Banner/Banner";
import ProductType from "component/ProductType/ProductType";
import "./Homepage.css";
import "react-loading-skeleton/dist/skeleton.css";
import {
  saveDeatailInternationalHeader,
  saveInternationalHeader,
  saveMobileScroll,
} from "features/homepage/homeDataSlice";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.min.css";
import "owl.carousel/dist/assets/owl.theme.default.min.css";
import {
  StyledHeadingdiv,
  StyledBordediv,
  StyledBackgroundColor,
  StyledHeadingContent,
} from "./Homepage.styles";
import CategoryPlaceholder from "asset/image/CategoryPlaceholder.png";
import { isElementInViewport } from "utils";
import {
  LazyLoadComponent,
  LazyLoadImage,
} from "react-lazy-load-image-component";
import About1 from "asset/image/New-Aboutus1.png";
import About1Webp from "asset/image/New-Aboutus1.webp";
import About2 from "asset/image/New-Aboutus2.png";
import About2Webp from "asset/image/New-Aboutus2.webp";
import Product from "component/Product/Product";

// import MoreHomePageContent from "./MoreHomePageContent";
const MoreHomePageContent = React.lazy(() => import("./MoreHomePageContent"));

const INTERNATION_CAT_ID = 494;
const INTERNATION_JI_ID = 159;

const HomePage = () => {
  const { categories, banner, newArrivals } = useSelector(
    (state) => state.home
  );
  const dispatch = useDispatch();
  const history = useHistory();
  const [showMoreContent, setShowMoreContent] = useState(false);
  const [loader, setLoader] = useState(false);

  // const { pathname } = useLocation();

  // const SortArray = (x, y) => {
  //   if (x.LastName < y.LastName) {
  //     return -1;
  //   }
  //   if (x.LastName > y.LastName) {
  //     return 1;
  //   }
  //   return 0;
  // };

  const exploreMore = (aboutkey) => {
    if (aboutkey === "about") {
      history.push("/about-us");
    } else {
      const state = { aboutKey: aboutkey };
      history.push("/about-us", state);
    }
  };

  useEffect(() => {
    dispatch(saveDeatailInternationalHeader(false));
    dispatch(saveInternationalHeader(true));
  }, []);

  useEffect(() => {
    if (window.innerWidth <= 480) {
      dispatch(saveMobileScroll(true));
    } else {
      dispatch(saveMobileScroll(false));
    }
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop =
        window.pageYOffset !== undefined
          ? window.pageYOffset
          : document.body.scrollTop;
      if (scrollTop > 50) {
        setShowMoreContent(true);
        window.removeEventListener("scroll", handleScroll);
      }
    };
    const bannerNode = document.querySelector(".banner-homepage-sk");
    if (
      (bannerNode && !isElementInViewport(bannerNode)) ||
      window.innerHeight > 1600
    ) {
      setShowMoreContent(true);
    } else window.addEventListener("scroll", handleScroll);
  }, []);

  const updateCategoriesDataforOwlCaraousal = (data) => {
    const updatedData = [];

    data.forEach((item) => {
      const newItem = { ...item };
      const subChildren = [];

      item.children_data.forEach((subItem) => {
        if (subItem.is_featured === "1") {
          subChildren.push({ ...subItem, parent_urlKey: item.url_key });
        }
      });

      delete newItem.children_data;
      updatedData.push(newItem);

      if (subChildren.length > 0) {
        updatedData.push(...subChildren);
      }
    });

    return updatedData;
  };

  const owlCarousalData = useMemo(() => {
    if (categories && categories.children_data) {
      console.log("categories.children_data", categories.children_data);

      return updateCategoriesDataforOwlCaraousal(categories.children_data);
    } else return [];
  }, [categories]);

  console.log("newArrivals", newArrivals);

  return (
    <div>
      <div>
        <Banner banner={banner} type="JBD" />
        <StyledBackgroundColor>
          <center>
            <h2>
              <StyledHeadingdiv>Product Categories</StyledHeadingdiv>
            </h2>
            <StyledBordediv></StyledBordediv>
          </center>
          <center>
            <StyledHeadingContent>
              {categories.description ||
                "Complete your home with reliable and stunningly pleasing Johnson Bathroom’s sanitary ware, faucets, kitchen sinks, vanity basins and allied products for your kitchen and bathroom. Explore hundreds of designs for your perfect match, consult our experts and discover the best ways to maintain the products for long-life."}
            </StyledHeadingContent>
          </center>
          <div className="d-block d-sm-none">
            <OwlCarousel
              className="grid productcategories owl-carousel owl-theme"
              items={1}
              loop
              nav
              dots={false}
            >
              {categories && categories.children_data
                ? owlCarousalData.map(
                    (slide, index) =>
                      slide.id !== INTERNATION_CAT_ID &&
                      slide.id !== INTERNATION_JI_ID &&
                      index < 7 && (
                        <div key={slide.id}>
                          <Link
                            to={
                              slide.level === 3
                                ? "/products/" +
                                  slide.parent_urlKey +
                                  "/" +
                                  slide.url_key
                                : "/products/" + slide.url_key
                            }
                          >
                            <ProductType
                              productId={slide.url_key}
                              imageUrl={slide.image_url}
                              productType={slide.name || ""}
                              productDetails={slide.description || ""}
                              catgorycontent={slide.fs_custom_homepage || ""}
                            />
                          </Link>
                        </div>
                      )
                  )
                : Array.from(Array(2).keys()).map((e) => (
                    <div key={e}>
                      <figure
                        className="effect-moses"
                        style={{ background: "transparent" }}
                      >
                        <img
                          src={CategoryPlaceholder}
                          alt=""
                          loading="lazy"
                          width={398}
                          height={459}
                        />
                      </figure>
                    </div>
                  ))}
            </OwlCarousel>
          </div>
          <div className="container grid d-none d-md-block">
            <div className="row">
              {categories && categories.children_data
                ? owlCarousalData.map(
                    (slide, index) =>
                      slide.id !== INTERNATION_CAT_ID &&
                      slide.id !== INTERNATION_JI_ID &&
                      index < 7 && (
                        <div
                          key={slide.id}
                          className="col-md-4"
                          style={{ position: "relative" }}
                        >
                          <Link
                            to={
                              slide.level === 3
                                ? "/products/" +
                                  slide.parent_urlKey +
                                  "/" +
                                  slide.url_key
                                : "/products/" + slide.url_key
                            }
                          >
                            <ProductType
                              productId={slide.url_key}
                              productType={slide.name || ""}
                              productDetails={slide.description || ""}
                              imageUrl={slide.image_url}
                              catgorycontent={slide.fs_custom_homepage || ""}
                            />
                          </Link>
                        </div>
                      )
                  )
                : Array.from(Array(3).keys()).map((e) => (
                    <div
                      key={e}
                      className="col-md-4"
                      style={{ position: "relative" }}
                    >
                      <figure
                        className="effect-moses"
                        style={{ background: "transparent" }}
                      >
                        <img
                          src={CategoryPlaceholder}
                          alt=""
                          loading="lazy"
                          width={398}
                          height={459}
                        />
                      </figure>
                    </div>
                  ))}
            </div>
          </div>
        </StyledBackgroundColor>
        {newArrivals && newArrivals.length && (
          <StyledBackgroundColor className="newArrivals">
            <center>
              <h2>
                <StyledHeadingdiv>New Arrivals</StyledHeadingdiv>
              </h2>
              <StyledBordediv></StyledBordediv>
            </center>
            <div className="d-block d-sm-none">
              <OwlCarousel
                className="grid productcategories owl-carousel owl-theme"
                items={1}
                loop
                nav
                dots={false}
              >
                {newArrivals && newArrivals.length
                  ? newArrivals.map((product, index) => (
                      <div key={index} style={{ padding: "10px" }}>
                        <Product
                          productname={product.name}
                          imageUrl={product.image_url}
                          available_color={product.available_color}
                          urlKey={product.url_key}
                          productId={product.id}
                          code={product.sku}
                          isNew={product.is_new}
                          productdiscription={product.description}
                          productprice={product.price}
                          productimages={product.image}
                          logoflagcomponent={
                            product.germfree_non_germ_free === "GF"
                              ? true
                              : false
                          }
                          logoInternationalcompo={
                            product.international_non_internationa === "JI"
                              ? true
                              : false
                          }
                          imageALT={product.image_alt}
                        />
                      </div>
                    ))
                  : Array.from(Array(2).keys()).map((e) => (
                      <div key={e}>
                        <figure
                          className="effect-moses"
                          style={{ background: "transparent" }}
                        >
                          <img
                            src={CategoryPlaceholder}
                            alt=""
                            loading="lazy"
                            width={398}
                            height={459}
                          />
                        </figure>
                      </div>
                    ))}
              </OwlCarousel>
            </div>
            <div className="container grid d-none d-md-block">
              <OwlCarousel
                className="grid productcategories owl-carousel owl-theme"
                items={4}
                loop
                nav
                dots={false}
              >
                {newArrivals && newArrivals.length
                  ? newArrivals.map((product, index) => (
                      <div key={index} style={{ padding: "10px" }}>
                        <Product
                          productname={product.name}
                          imageUrl={product.image_url}
                          available_color={product.available_color}
                          urlKey={product.url_key}
                          productId={product.id}
                          code={product.sku}
                          isNew={product.is_new}
                          productdiscription={product.description}
                          productprice={product.price}
                          productimages={product.image}
                          logoflagcomponent={
                            product.germfree_non_germ_free === "GF"
                              ? true
                              : false
                          }
                          logoInternationalcompo={
                            product.international_non_internationa === "JI"
                              ? true
                              : false
                          }
                          imageALT={product.image_alt}
                        />
                      </div>
                    ))
                  : Array.from(Array(2).keys()).map((e) => (
                      <div key={e}>
                        <figure
                          className="effect-moses"
                          style={{ background: "transparent" }}
                        >
                          <img
                            src={CategoryPlaceholder}
                            alt=""
                            loading="lazy"
                            width={398}
                            height={459}
                          />
                        </figure>
                      </div>
                    ))}
              </OwlCarousel>
            </div>
          </StyledBackgroundColor>
        )}
      </div>
      <LazyLoadComponent>
        <section className="home-about">
          <div className="shape"></div>
          <div className="container">
            <div className="row">
              <div className="col-md-6 aboutindex">
                <div className="main-heading whitetext-heading">
                  <h2>About Johnson Bathrooms</h2>
                  <p>
                    Established in 1958, H & R Johnson (India), is the pioneer
                    of ceramic tiles in India. Over the past five decades, H & R
                    Johnson has added various product categories to offer
                    end-to-end lifestyle solutions covering Tiles, Sanitaryware,
                    Faucets, Wellness, Engineered Marble & Quartz.
                  </p>
                  <a
                    className="explore-more"
                    onClick={(e) => exploreMore("about")}
                  >
                    Know More
                  </a>
                </div>
              </div>
              <div className="col-md-6 aboutindex">
                <div className="row">
                  <div className="col-md-6">
                    <picture>
                      <source type="image/webp" srcSet={About1Webp} />
                      <source type="image/png" srcSet={About1} />
                      <LazyLoadImage
                        src={About1}
                        alt=""
                        width={554}
                        height={426}
                      />
                    </picture>
                  </div>
                  <div className="col-md-6">
                    <picture>
                      <source type="image/webp" srcSet={About2Webp} />
                      <source type="image/png" srcSet={About2} />
                      <LazyLoadImage
                        src={About2}
                        alt=""
                        width={638}
                        height={426}
                      />
                    </picture>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </LazyLoadComponent>
      {showMoreContent && (
        <React.Suspense fallback={null}>
          <MoreHomePageContent />
        </React.Suspense>
      )}

      {loader && (
        <div className="loading-overlay">
          <div className="bounce-loader">
            <div className="bounce1"></div>
            <div className="bounce2"></div>
            <div className="bounce3"></div>
          </div>
        </div>
      )}
    </div>
  );
};

export default HomePage;
