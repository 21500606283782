import { apiHandler } from "api";
import axios from "axios";
import {
  saveCatagory,
  saveBanner,
  saveNewArrival,
  saveStateList,
  saveStaticBlock,
  setLoadingCategories,
} from "features/homepage/homeDataSlice";
import { endpoint } from "./endpoint";

export const getHomepageData = ({ dispatch }) => {
  const data = {
    block_id: "home_page_footer_content",
  };
  return axios
    .all([
      // apiHandler({ url: endpoint.BANNERS }),
      apiHandler({ url: endpoint.CATEGORY_TREE }),

      apiHandler({
        url: endpoint.STATICHOMEPAGE,
        method: "POST",
        data: data,
      }),
      apiHandler({ url: endpoint.NEW_ARRIVALS }),

      // apiHandler({ url: endpoint.STATE_LIST }),
    ])
    .then(
      axios.spread((categories, get_cms_staticblock, newArrivals) => {
        dispatch(setLoadingCategories(false));
        // if (!banners.data.error_code) dispatch(saveBanner(banners.data));
        if (!categories.data.error_code)
          dispatch(saveCatagory(categories.data));
        if (!newArrivals.data.error_code)
          dispatch(saveNewArrival(newArrivals.data));
        dispatch(saveStaticBlock(get_cms_staticblock.data));
        // console.log('staic Block ', get_cms_staticblock.data);

        return {
          // banners: banners.data,
          categories: categories.data,
          // newArrivals: newArrivals.data,
          get_cms_staticblock: get_cms_staticblock.data,
        };
      })
    );
};
