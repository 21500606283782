import { combineReducers } from 'redux';
import counterReducer from 'features/counter/counterSlice';
import homeDataReducer from 'features/homepage/homeDataSlice';
import cartReducer from 'features/cart/cartSlice';
import wishlistSlice from 'features/wishlist/wishlistSlice';
import productsListReducer from 'features/productsList/productsListSlice';
import apploaderReducer from 'features/appLoader/apploaderSlice';
import catalogueDataReducer from 'features/catalogue/catalogueDataSlice';
import loginSliceReducer from 'features/login/LoginSlice';
import dealerlistReducer from 'features/dealerlist/DealerListSlice';
import technicianListReducer from 'features/technician/technicianListSlice';
import TipcareReducer from 'features/tips-care/tipsandcareSlice';
import SearchProductReducer from 'features/productsearchlist/productsearchListSlice';
import BathroomConceptReducer from 'features/bathroomconcept/bathconceptDataSlice';
import StaticBlockReducer from 'features/homepage/homeDataSlice';

export default combineReducers({
	/**Add more reducers here*/
	counter: counterReducer,
	home: homeDataReducer,
	cart: cartReducer,
	wishlist: wishlistSlice,
	productsList: productsListReducer,
	appLoader: apploaderReducer,
	catalogue: catalogueDataReducer,
	login: loginSliceReducer,
	dealers: dealerlistReducer,
	technician: technicianListReducer,
	tipcares: TipcareReducer,
	filterProductsSearch: SearchProductReducer,
	bathconcept: BathroomConceptReducer,
});
