import React, { useState, useEffect } from "react";
import Joi from "joi";
import { useSelector } from "react-redux";
import { apiHandler } from "api";
import { endpoint } from "../../api/endpoint";
import { Modal, Button } from "react-bootstrap";

const LeadForm = () => {
  const [showForm, setShowForm] = useState(false);
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    contactNumber: "",
    email: "",
    city: "",
    state: "",
    collectionName: "",
    message: "",
  });

  const { categories } = useSelector((state) => state.home);
  const [errors, setErrors] = useState({});

  const schema = Joi.object({
    firstName: Joi.string()
      .pattern(/^[a-zA-Z]+$/)
      .min(2)
      .max(30)
      .required()
      .messages({
        "string.empty": "First Name is required",
        "string.min": "First Name must be at least 2 characters",
        "string.max": "First Name cannot exceed 30 characters",
        "string.pattern.base": "First Name must only contain letters",
      }),
    lastName: Joi.string()
      .pattern(/^[a-zA-Z]+$/)
      .min(2)
      .max(30)
      .required()
      .messages({
        "string.empty": "Last Name is required",
        "string.min": "Last Name must be at least 2 characters",
        "string.max": "Last Name cannot exceed 30 characters",
        "string.pattern.base": "Last Name must only contain letters",
      }),
    contactNumber: Joi.string()
      .pattern(/^\d{10}$/)
      .required()
      .messages({
        "string.empty": "Contact Number is required",
        "string.pattern.base": "Contact Number must be valid",
      }),
    email: Joi.string()
      .email({ tlds: { allow: false } })
      .required()
      .messages({
        "string.empty": "Email is required",
        "string.email":
          "Email must be a valid format (e.g. example@domain.com)",
      }),
    city: Joi.string()
      .pattern(/^[a-zA-Z\s]+$/)
      .min(2)
      .required()
      .messages({
        "string.empty": "City is required",
        "string.min": "City must be at least 2 characters",
        "string.pattern.base": "City must only contain letters",
      }),
    state: Joi.string()
      .pattern(/^[a-zA-Z\s]+$/)
      .min(2)
      .required()
      .messages({
        "string.empty": "State is required",
        "string.min": "State must be at least 2 characters",
        "string.pattern.base": "State must only contain letters",
      }),
    collectionName: Joi.string().required().messages({
      "string.empty": "Collection selection is required",
    }),
    message: Joi.string().min(10).max(500).messages({
      "string.min": "Message must be at least 10 characters long",
      "string.max": "Message cannot exceed 500 characters",
    }),
  });

  useEffect(() => {
    const isPopupShown = sessionStorage.getItem("popupShown");

    if (!isPopupShown) {
      const timer = setTimeout(() => {
        setShowForm(true);
        sessionStorage.setItem("popupShown", "true");
      }, 30000);

      return () => clearTimeout(timer);
    }
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const validateField = (name, value) => {
    const { error } = schema.extract(name).validate(value);
    return error ? error.details[0].message : null;
  };

  const handleBlur = (e) => {
    const { name, value } = e.target;
    const errorMessage = validateField(name, value);
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: errorMessage,
    }));
  };

  const validateForm = () => {
    const { error } = schema.validate(formData, { abortEarly: false });
    if (!error) return null;

    const validationErrors = {};
    error.details.forEach((detail) => {
      validationErrors[detail.path[0]] = detail.message;
    });
    return validationErrors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = validateForm();
    if (validationErrors) {
      setErrors(validationErrors);
      return;
    }
    setErrors({});
    let productData = {
      parameters: {
        first_name: formData.firstName,
        last_name: formData.lastName,
        contact_number: formData.contactNumber,
        customer_email: formData.email,
        city: formData.city,
        state: formData.state,
        collection_name: formData.collectionName,
        comment: formData.message,
      },
    };
    const result = await apiHandler({
      url: `${endpoint.LEAD_FORM}`,
      method: "POST",
      data: productData,
    });
    if (result.data.status === "true") {
      setFormData({
        firstName: "",
        lastName: "",
        contactNumber: "",
        email: "",
        city: "",
        state: "",
        collectionName: "",
        message: "",
      });
      handleCloseForm();
      window.location.href = "/thank-you";
    }
  };

  const handleCloseForm = () => {
    setShowForm(false);
  };

  if (!showForm) return null;

  return (
    <Modal className="" show={showForm} onHide={handleCloseForm}>
      <Modal.Header closeButton>
        <Modal.Title>Lead Form</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row">
          <div className="col-md-6">
            <div className="form-group">
              <label htmlFor="firstName">
                First Name <span className="required">*</span>
              </label>
              <input
                type="text"
                name="firstName"
                className="form-control"
                placeholder="First Name"
                value={formData.firstName}
                onChange={handleInputChange}
                onBlur={handleBlur}
                required
              />
              {errors.firstName && (
                <span className="error_message">{errors.firstName}</span>
              )}
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label htmlFor="lastName">
                Last Name <span className="required">*</span>
              </label>
              <input
                type="text"
                name="lastName"
                className="form-control"
                placeholder="Last Name"
                value={formData.lastName}
                onChange={handleInputChange}
                onBlur={handleBlur}
                required
              />
              {errors.lastName && (
                <span className="error_message">{errors.lastName}</span>
              )}
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label htmlFor="contactNumber">
                Contact Number <span className="required">*</span>
              </label>
              <input
                type="tel"
                name="contactNumber"
                className="form-control"
                placeholder="Contact Number"
                value={formData.contactNumber}
                onChange={handleInputChange}
                onBlur={handleBlur}
                required
              />
              {errors.contactNumber && (
                <span className="error_message">{errors.contactNumber}</span>
              )}
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label htmlFor="email">
                Email <span className="required">*</span>
              </label>
              <input
                type="email"
                name="email"
                className="form-control"
                placeholder="Email"
                value={formData.email}
                onChange={handleInputChange}
                onBlur={handleBlur}
                required
              />
              {errors.email && (
                <span className="error_message">{errors.email}</span>
              )}
            </div>
          </div>

          <div className="col-md-6">
            <div className="form-group">
              <label htmlFor="city">
                City <span className="required">*</span>
              </label>
              <input
                type="text"
                name="city"
                className="form-control"
                placeholder="City"
                value={formData.city}
                onChange={handleInputChange}
                onBlur={handleBlur}
                required
              />
              {errors.city && (
                <span className="error_message">{errors.city}</span>
              )}
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label htmlFor="state">
                State <span className="required">*</span>
              </label>
              <input
                type="text"
                name="state"
                className="form-control"
                placeholder="State"
                value={formData.state}
                onChange={handleInputChange}
                onBlur={handleBlur}
                required
              />
              {errors.state && (
                <span className="error_message">{errors.state}</span>
              )}
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label htmlFor="collectionName">
                Select Collection <span className="required">*</span>
              </label>
              <select
                name="collectionName"
                className="form-control"
                value={formData.collectionName}
                onChange={handleInputChange}
                onBlur={handleBlur}
                required
              >
                <option value="">Select Collection</option>
                {categories?.children_data?.map((child) => (
                  <option key={child.id} value={child.name}>
                    {child.name}
                  </option>
                ))}
              </select>
              {errors.collectionName && (
                <span className="error_message">{errors.collectionName}</span>
              )}
            </div>
          </div>
          <div className="col-md-12">
            <div className="form-group">
              <label htmlFor="message">
                Message <span className="required">*</span>
              </label>
              <textarea
                name="message"
                className="form-control"
                placeholder="Message"
                value={formData.message}
                onChange={handleInputChange}
                onBlur={handleBlur}
                required
              ></textarea>
              {errors.message && (
                <span className="error_message">{errors.message}</span>
              )}
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleCloseForm}>
          Close
        </Button>
        <Button
          onClick={(e) => {
            handleSubmit(e);
          }}
          variant="primary"
        >
          Submit
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default LeadForm;
