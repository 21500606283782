import React, { useEffect, useState } from "react";
import Header from "./Header/Header";
const InternationalHeader = React.lazy(() =>
  import("./Header/InternationalHeader")
);
const Footer = React.lazy(() => import("./Footer/Footer"));
import { createGlobalStyle, ThemeProvider } from "styled-components";
import { useLocation } from "react-router-dom";
const ListingInternationalHeader = React.lazy(() =>
  import("./Header/ListingInternationalheader")
);
import { useSelector } from "react-redux";
import { isElementInViewport } from "utils";

const GlobalStyle = createGlobalStyle`
  body {
    color: ${(props) => (props.whiteColor ? "white" : "black")};
    font-family: ${(props) => props.theme.fontFamily};
  }
`;

const Layout = ({ children }) => {
  const [refresh, setRefresh] = useState(false);
  const location = useLocation();
  const [showFooter, setShowFooter] = useState(false);

  const currentUrls = location.pathname;
  const currentUrl = location.pathname;
  const [sideDrawerOpen, setSideDrawerOpen] = useState(false);

  const drawerToggleClickHandler = () => {
    setSideDrawerOpen(!sideDrawerOpen);
  };

  const { internationalheader, detailInternationalHeader, footerShow } =
    useSelector((state) => state.home);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop =
        window.pageYOffset !== undefined
          ? window.pageYOffset
          : document.body.scrollTop;
      if (scrollTop > 100) {
        setShowFooter(true);
        window.removeEventListener("scroll", handleScroll);
      }
    };

    const bannerNode = document.querySelector(".banner");
    if (
      (bannerNode && !isElementInViewport(bannerNode)) ||
      window.innerHeight > 1600
    ) {
      setShowFooter(true);
    } else window.addEventListener("scroll", handleScroll);
  }, []);

  useEffect(() => {
    if (
      window.location.pathname !== "/" &&
      window.location.pathname.indexOf("product") === -1
    ) {
      setShowFooter(true);
    } else if (window.location.pathname.includes("search-products")) {
      setShowFooter(true);
    }
  }, [window.location.pathname]);

  return (
    <>
      <ThemeProvider
        style={{ position: "relative" }}
        theme={{ fontFamily: "Open Sans" }}
      >
        {currentUrls !== "/campaign" ? (
          <>
            {currentUrls !== "/" &&
            (currentUrls === "/international" ||
              currentUrls.includes("/products/johnson-international") ||
              detailInternationalHeader) ? (
              <>
                {internationalheader === true && (
                  <React.Suspense fallback={null}>
                    <InternationalHeader
                      drawerToggleClickHandler={() =>
                        drawerToggleClickHandler()
                      }
                    />
                  </React.Suspense>
                )}
                {internationalheader === false && (
                  <React.Suspense fallback={null}>
                    <ListingInternationalHeader
                      drawerToggleClickHandler={() =>
                        drawerToggleClickHandler()
                      }
                    />
                  </React.Suspense>
                )}
              </>
            ) : (
              <Header
                refresh={refresh}
                drawerToggleClickHandler={() => drawerToggleClickHandler()}
              />
            )}
            <GlobalStyle />
            {/* {backdrop} */}
            <main className="main innerpage-sk">{children}</main>
            {showFooter || footerShow ? (
              <React.Suspense fallback={null}>
                <Footer />
              </React.Suspense>
            ) : null}
          </>
        ) : (
          <main className="innerpage-sk">{children}</main>
        )}
      </ThemeProvider>
    </>
  );
};

export default Layout;
