import styled from 'styled-components';

export const StyleheadingButton = styled.button`
background-color: ${(props) => props.color};
display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    width: 15px;
    height: 15px;
    line-height: 23px;
    border: 1px solid #e7e7e7;
    border-radius: 14px;
    text-align: center;
    text-decoration: none;
    font-family: 'Poppins', sans-serif;
	margin: 2px;
}
`;
