import { createSlice } from '@reduxjs/toolkit';

export const loginSlice = createSlice({
	name: 'login',
	initialState: {
		authData: {
			authToken: null,
			isAuthenticated: false,
			isSkip: false,
		},
		userData: {},
	},
	reducers: {
		saveAuthData: (state, action) => {
			state.authData = action.payload;
		},
		saveUserData: (state, action) => {
			state.userData = action.payload;
		},
		saveSkipFlag: (state, action) => {
			state.authData = {
				...state.authData,
				isSkip: action.payload,
			};
		},
	},
});

export const { saveAuthData, saveUserData, saveSkipFlag } = loginSlice.actions;

export default loginSlice.reducer;
