import { createSlice } from '@reduxjs/toolkit';

const CONCEPT = [
	{
		'Johnson Bathrooms': [
			{
				id: '1',
				title: 'test',
				description: null,
				image_url: 'http://20.204.0.166/media/concept/category_banner_2.jpg',
				theme_url: 'http://20.204.0.166/media/concept/banner_cooler2_1.jpg',
			},
		],
	},
];

export const bathconceptDataSlice = createSlice({
	name: 'Bathroomconcept',
	initialState: {
		bathroomconcept: [],
	},
	reducers: {
		// Redux Toolkit allows us to write "mutating" logic in reducers. It
		// doesn't actually mutate the state because it uses the immer library,
		// which detects changes to a "draft state" and produces a brand new
		// immutable state based off those changes
		saveBathroomBoncept: (state, action) => {
			state.bathroomconcept = [...action.payload];
			// console.log('....Explore  Redux ', action.payload);
		},
	},
});

export const { saveBathroomBoncept } = bathconceptDataSlice.actions;

export default bathconceptDataSlice.reducer;
