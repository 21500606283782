import { createSlice } from '@reduxjs/toolkit';

export const appLoaderSlice = createSlice({
	name: 'appLoader',
	initialState: { showProductRedirectLoader: false },
	reducers: {
		showProductRedirectLoader: (state, action) => {
			state.showProductRedirectLoader = action.payload;
		},
	},
});

export const { showProductRedirectLoader } = appLoaderSlice.actions;

export default appLoaderSlice.reducer;
